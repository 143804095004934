import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Looking for the right idea",
  "date": "2021-12-12T00:00:00.000Z"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Searching for the right idea`}</h1>
    <p>{`Guidance on how to find and validate the right idea `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      